@import './colors';

.alert-style {
    background-color: $mainColor3;
    color: #084298;
    border-color: #b6d4fe;
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: opacity .15s linear;
}

.alert-enter {
    opacity: 0;
    transform: scale(0.9);
}
.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 500ms;
}
.alert-exit {
    opacity: 1;
}
.alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 500ms;
}


    /* width */
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    };

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    };

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background-color: $mainColor1;
        border-radius: 5px;
    };

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: $mainColor2;
    };

    // /* For Firefox */
    html {
        scrollbar-color: $mainColor1 transparent; /* thumb and track color */
        scrollbar-width: thin;
    };


