@import '../../colors';

div.ReactModalPortal {
    div.ReactModal__Overlay--after-open {
        z-index: 20;
        position: fixed;
        inset: 0px;
        background-color: transparent !important;
        div.metadata-modal {
            position: absolute;
            transform: translate(-50%, -50%);
            padding: 0px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
            overflow: hidden;
            margin: auto;
            inset: 50% auto auto 50%;
            background: rgb(255, 255, 255);
            outline: none;
            border: none;
            border-radius: 0px;
            @media (min-width: 461px) {
                height: 80%;
                width: 80%;
                max-width: 1000px;
                max-height: 800px;
            }
            @media (max-width: 460px) {
                height: 100%;
                width: 100%;
                max-height: none;
                max-width: none;
            }

            div.metadata-content {
                overflow-y: auto;
            }
        }

    }
}
