
// Colors
$mainColor1: #0064af;
$mainColor2: #009ae1;
$mainColor3: #49c2f1;

$transparentMain: #80dbff40;

$mainColorselected1: #024c85;

$hover: #F0F0F0;

$secondaryColorLightBlue: #00b0cc; // 70,50,30
$secondaryColorGreen: #207a43; // 70,50,30
$secondaryColorLightGreen: #8dcb6d; // 70,50,30
$secondaryColorYellow: #ffc300; // 70,50,30
$secondaryColorPurple: #910aa3; // 70,50,30
$secondaryColorDarkOrange: #c73f00; // 70,50,30
$secondaryColorOrange: #ff5100; // 70,50,30
$secondaryColorPink: #e50083; // 70,50,30
$black: #000000; // 80,50,20
$white: #ffffff;
$darkGrey: #717070;

$primaryColor: #009ae1;
$primaryTextColor: #fff;

// Component colors
$layout-header-background-color: $mainColor2;